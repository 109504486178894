import { useEffect, useState } from 'react';

const SvgInline = (props: { url: RequestInfo | URL | any }) => {
  const [svg, setSvg] = useState<any>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isErrored, setIsErrored] = useState(false);

  useEffect(() => {
    fetch(props?.url)
      .then((res) => {
        return res?.text();
      })
      .then(setSvg)
      .catch(setIsErrored)
      .then(() => setIsLoaded(true));
  }, [props.url]);

  return (
    <span
      className={`svgInline svgInline--${isLoaded ? 'loaded' : 'loading'} ${
        isErrored ? 'svgInline--errored' : ''
      }`}
      dangerouslySetInnerHTML={{ __html: svg }}
    />
  );
};

export default SvgInline;
