import { styled } from '@mui/material';

export const SpaceStyled = styled('div')(() => ({
  overflow: 'scroll',
  visibility: 'hidden',
  flex: 'none',
}));

export const SpaceStyledMaunualTrigger = styled('div')(() => ({
  overflow: 'scroll',
  visibility: 'hidden',
  flex: 'none',
  display: 'none',
}));
