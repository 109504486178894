import { styled } from '@mui/material';
import { Form } from 'formik';
import { LocationOnIcon, MenuIcon } from '../../../assets';
import Button from '../../button/Button';
import SimpleSelect from '../../select/Select';
import { TextSize } from '../../../models/enum';

export const StyledContentWrapper = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(200 / 4),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpointsMediaQuery.down('lg')]: {
    height: 'auto',
  },
}));

export const HeaderContainer = styled('header')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  top: theme.spacings.unit(0),
  left: theme.spacings.unit(0),
  textAlign: 'left',
  boxShadow: `0 ${theme.spacings.unit(0.25)} 0 ${theme.colors.drawerDivider}`,
  width: '100%',
  paddingBottom: 0,
  paddingLeft: 'env(safe-area-inset-left)',
  paddingRight: 'env(safe-area-inset-right)',

  [theme.breakpointsMediaQuery.down('lg')]: {
    paddingTop: '0',
  },
}));

export const Nav = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '100vw',
  margin: '0 auto',
  justifyContent: 'center',
  [theme.breakpointsMediaQuery.down('lg')]: {
    minHeight: theme.spacings.unit(60 / 4),
    backgroundColor: theme.colors.white,
  },
}));

const MobileBar = styled('nav')(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.white,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  maxWidth: '100vw',
  margin: '0 auto',
  justifyContent: 'center',
  [theme.breakpointsMediaQuery.down('lg')]: {
    minHeight: theme.spacings.unit(60 / 4),
    backgroundColor: theme.colors.white,
  },
}));

export default MobileBar;

export const FirstBar = styled(Nav)(({ theme }) => ({
  backgroundColor: theme.colors.secondaryBackground,
  width: '100%',
  paddingTop: theme.spacings.unit(1),
  paddingBottom: theme.spacings.unit(1),
}));

export const FirstBarContainer = styled('span')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  maxWidth: '100vw',
  zIndex: '999',
  padding: `${theme.spacings.unit(8 / 4)} ${theme.spacings.unit(26)}`,
  [theme.breakpointsMediaQuery.up('xl')]: {
    maxWidth: theme.spacings.unit(1960 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
  },
}));

export const SecondBar = styled(Nav)(({ theme }) => ({
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(6 / 4)} ${theme.spacings.unit(
      26
    )} ${theme.spacings.unit(6 / 4)} ${theme.spacings.unit(26)}`,
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    maxWidth: theme.spacings.unit(1960 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
    paddingTop: theme.spacings.unit(16 / 4),
    paddingBottom: theme.spacings.unit(16 / 4),
  },
  width: '100%',
  alignItems: 'center',
  paddingTop: 0,
}));

export const ThirdBar = styled(Nav, {
  shouldForwardProp: (prop) =>
    !['megamenuMaxWidthDesktop'].includes(prop as string),
})(({ theme, megamenuMaxWidthDesktop }) => ({
  maxWidth: megamenuMaxWidthDesktop
    ? `${megamenuMaxWidthDesktop + 40}px`
    : theme.spacings.unit(1960 / 4),
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(4 / 4)} ${theme.spacings.unit(
      26
    )} ${theme.spacings.unit(16 / 4)} ${theme.spacings.unit(26)}`,
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
    paddingTop: theme.spacings.unit(16 / 4),
    paddingBottom: theme.spacings.unit(16 / 4),
  },
  width: '100%',
}));

export const Logo = styled('span')(({ theme }) => ({
  height: theme.spacings.unit(52 / 4),
  width: theme.spacings.unit(128 / 4),
  display: 'block',
  position: 'relative',

  [theme.breakpointsMediaQuery.down('lg')]: {
    height: theme.spacings.unit(42 / 4),
    width: theme.spacings.unit(112 / 4),
  },
}));

export const LogoDesktopStyeld = styled(Logo)(({ theme }) => ({
  height: theme.spacings.unit(56 / 4),
  width: theme.spacings.unit(140 / 4),
  display: 'block',
  position: 'relative',
}));

export const LinkLogo = styled('a')(() => ({
  cursor: 'pointer',
  textAlign: 'center',
  display: 'inline-flex',
}));

export const LinkLogoStyled = styled(LinkLogo)(({ theme }) => ({
  position: 'absolute',
  marginTop: `-${theme.spacings.unit(12 / 4)}`,
  [theme.breakpointsMediaQuery.up('lg')]: {
    marginTop: `-${theme.spacings.unit(6 / 4)}`,
  },
}));

export const StyledMenuIcon = styled(MenuIcon)(({ theme }) => ({
  width: theme.spacings.unit(24 / 4),
  height: theme.spacings.unit(24 / 4),
  cursor: 'pointer',
  fontSize: theme.fonts.utility.size(2),
  marginRight: theme.spacings.unit(16 / 4),
}));

export const MainMenu = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('lg')]: {
    display: 'none',
  },
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  transitionProperty: 'all',
  transitionDuration: '.5s',
  transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
}));

export const SubMenu = styled('nav')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('lg')]: {
    display: 'none',
  },
  touchAction: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  overflowY: 'hidden',
  transitionProperty: 'all',
  transitionDuration: '.5s',
  transitionTimingFunction: 'cubic-bezier(0, 1, 0.5, 1)',
  [theme.breakpointsMediaQuery.up('sm')]: {
    transitionProperty: 'none',
    maxHeight: 'initial',
    overflowY: 'none',
    position: 'relative',
    flexDirection: 'row',
    top: theme.spacings.unit(0),
    width: '100%',
    maxWidth: '100vw',
  },
}));

export const SearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
}));

export const SectionLeft = styled('div')(({ theme }) => ({
  flex: 1,
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingRight: theme.spacings.unit(20 / 4),
  },
  '@media print': {
    visibility: 'hidden',
  },
}));

export const SecondBarSectionLeft = styled(SectionLeft)(({ theme }) => ({
  height: theme.spacings.unit(56 / 4),
  position: 'relative',
  alignItems: 'baseline',
}));

export const SectionCenter = styled('div')(() => ({
  width: '100%',
}));

export const SectionLogoCenter = styled(SectionCenter)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: 'auto',
}));

export const SectionSearch = styled('div')(() => ({
  flex: 1,
}));

export const SectionRight = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'right',
  ['& > form:focus-within']: {
    width: '60%',
  },
  ['& > form']: {
    width: '30%',
    minWidth: theme.spacings.unit(200 / 4),
    transition: 'width 0.3s',
  },
  ['& > form input']: {
    width: '100%',
    lineHeight: '1.5rem',
  },
  ['& > span > span > svg']: {
    marginTop: theme.spacings.unit(3 / 4),
  },
  '@media print': {
    visibility: 'hidden',
  },
}));

export const Pages = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  position: 'relative',
}));

export const LocaleSelect = styled((props: any) => <SimpleSelect {...props} />)(
  ({ theme }) => ({
    minWidth: 'max-content',
    maxWidth: theme.spacings.unit(120 / 4),
    lineHeight: '1rem',
  })
);

export const StyledForm = styled(({ ...props }) => <Form {...props} />)(
  ({ theme }) => ({
    [theme.breakpointsMediaQuery.down('lg')]: {
      display: 'none',
    },
    display: 'flex',
    flexDirection: 'row',
  })
);

export const SearchButton = styled('button')(({ theme }) => ({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  padding: theme.spacings.unit(0),
}));

export const StyledLocationIcon = styled(LocationOnIcon)(() => ({
  width: '0.8em',
  height: '0.8em',
}));

export const ReservedAreaButton = styled(Button, {
  shouldForwardProp: (prop) => !['styleBtn'].includes(prop as string),
})(({ theme, textSize }) => {
  let sizeStyle = {};

  switch (textSize) {
    case TextSize.small:
      sizeStyle = {
        fontSize: '.8rem',
      };
      break;
    case TextSize.medium:
    default:
      sizeStyle = {
        fontSize: '1rem',
      };
      break;
    case TextSize.large:
      sizeStyle = {
        fontSize: '1.2rem',
      };
      break;
  }

  return {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: 0,
    cursor: 'pointer',
    marginLeft: theme.spacings.unit(5),
    [theme.breakpointsMediaQuery.down('lg')]: {
      margin: 0,
      maxWidth: theme.spacings.unit(24 / 4),
      maxHeight: theme.spacings.unit(24 / 4),
      minWidth: theme.spacings.unit(24 / 4),
      minHeight: theme.spacings.unit(24 / 4),
    },
    backgroundColor: 'transparent',
    padding: `${theme.spacings.unit(1 / 2)} ${theme.spacings.unit(1)}`,
    color: theme.colors.primary,
    textTransform: 'capitalize',
    borderRadius: 0,
    '&:hover, &:active': {
      backgroundColor: 'transparent',
      color: theme.colors.primary,
    },
    ...sizeStyle,
    '.MuiButton-endIcon': {
      fontSize: '.8rem',
      color: theme.colors.primary,
      marginLeft: theme.spacings.unit(2),
      [theme.breakpointsMediaQuery.down('lg')]: {
        margin: 0,
      },
      '& svg': {
        height: theme.spacings.unit(24 / 4),
        width: theme.spacings.unit(24 / 4),
        marginLeft: theme.spacings.unit(2),
        [theme.breakpointsMediaQuery.down('lg')]: {
          margin: 0,
        },
        fill: 'currentColor',
        path: {
          fill: 'currentColor',
        },
      },
    },
  };
});

export const ReservedAreaButtonTextWrapper = styled('span')(({ theme }) => ({
  '&:hover, &:active': {
    backgroundColor: 'transparent',
    color: theme.colors.primary,
    borderBottom: `${theme.spacings.unit(1 / 2)} solid`,
  },
}));

export const MobileMenuStyled = styled('nav')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: `0 ${theme.spacings.unit(36 / 4)}`,
  marginRight: theme.spacings.unit(16 / 4),

  '& > :last-child': {
    marginRight: 0,
  },
}));

export const RightMobileMenuStyled = styled(MobileMenuStyled)(() => ({
  paddingLeft: 0,
  margin: 0,
  '&& button': {
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

export const LeftMobileMenuStyled = styled(MobileMenuStyled)(() => ({
  marginRight: 0,
}));

export const LocalizeButtonStyled = styled(Button, {
  shouldForwardProp: (prop) => !['styleBtn'].includes(prop as string),
})(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    margin: 0,
    cursor: 'pointer',
    marginLeft: theme.spacings.unit(5),
    [theme.breakpointsMediaQuery.down('lg')]: {
      margin: 0,
      maxWidth: theme.spacings.unit(24 / 4),
      maxHeight: theme.spacings.unit(24 / 4),
      minWidth: theme.spacings.unit(24 / 4),
      minHeight: theme.spacings.unit(24 / 4),
    },
    backgroundColor: 'transparent',
    padding: `${theme.spacings.unit(1 / 2)} ${theme.spacings.unit(1)}`,
    color: theme.colors.primary,
    textTransform: 'capitalize',
    borderRadius: 0,
    '&:hover, &:active': {
      backgroundColor: 'transparent',
      color: theme.colors.primary,
    },
    '.MuiButton-endIcon': {
      fontSize: '.8rem',
      color: theme.colors.primary,
      marginLeft: theme.spacings.unit(2),
      [theme.breakpointsMediaQuery.down('lg')]: {
        margin: 0,
      },
      '& svg': {
        height: theme.spacings.unit(24 / 4),
        width: theme.spacings.unit(24 / 4),
        marginLeft: theme.spacings.unit(2),
        [theme.breakpointsMediaQuery.down('lg')]: {
          margin: 0,
        },
        fill: 'currentColor',
        path: {
          fill: 'currentColor',
        },
      },
    },
  };
});

export const MegaMenuContainer = styled('nav', {
  shouldForwardProp: (prop) => !['open'].includes(prop as string),
})(({ theme, open }) => ({
  position: 'absolute',
  top: theme.megamenu.heigthFixer,
  background: theme.colors.white,
  zIndex: '1',
  borderBottom: open
    ? `${theme.borders.unit(1)} solid ${theme.colors.borderMegamenu}`
    : theme.spacings.unit(0),
  boxShadow: open ? theme.megamenu.boxShadow : 'none',
  borderTop: theme.spacings.unit(0),
  width: '100%',
  '@media print': {
    display: 'none',
  },
}));
