import { List } from '@mui/material';
import Link from 'next/link';
import { styled } from '@mui/material';
import { CloseIcon } from '../../../assets';

export const ListStyled = styled(List)(() => ({}));
export const LinkStyled = styled(Link)(() => ({}));

export const HamburgerMenuContainer = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('lg')]: {
    position: 'absolute',
    top: 0,
    backgroundColor: theme.colors.background,
    width: '100%',
    height: 'calc(100vh)',
    zIndex: 1,
    overflow: 'auto',
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    display: 'none',
  },
}));

export const HamburgerMenuBodyContainer = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('lg')]: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacings.unit(128 / 4),
  },
}));

export const LinkLogo = styled('a')(() => ({
  cursor: 'pointer',
  textAlign: 'center',
  display: 'inline-flex',
}));

export const Logo = styled('span')(({ theme }) => ({
  height: theme.spacings.unit(52 / 4),
  width: theme.spacings.unit(128 / 4),
  display: 'block',
  position: 'relative',

  [theme.breakpointsMediaQuery.down('lg')]: {
    height: theme.spacings.unit(42 / 4),
    width: theme.spacings.unit(135 / 4),
  },
}));

export const HamburgerMenuHeader = styled('div')(({ theme }) => ({
  marginLeft: theme.spacings.unit(36 / 4),
  marginRight: theme.spacings.unit(36 / 4),
  borderBottom: `${theme.spacings.unit(1 / 4)} solid unset`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: theme.spacings.unit(60 / 4),
}));

export const ModileMenuContainerStyled = styled('div')(({ theme }) => ({}));

const CloseComponent = ({ ...props }: { onClick?: () => void }) => {
  return (
    <span>
      <CloseIcon {...props} />
    </span>
  );
};

export const CloseStyled = styled(CloseComponent)(({ theme }) => ({
  width: theme.spacings.unit(24 / 4),
  height: theme.spacings.unit(24 / 4),
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: theme.spacings.unit(1),
  color: theme.colors.primary,
  fontSize: theme.spacings.unit(40 / 4),
  padding: '0px',
  fontWeight: 'bold',
  opacity: '0.8',
  '&:hover': {
    opacity: '1',
  },
}));

export const MenuDivider = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(20 / 4),
}));
