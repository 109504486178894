import * as React from 'react';

import { Link as LinkMenuType } from '../../../models/domain/interfaces';
import { LinkStyled } from '../mobile-menu/MobileMenu.style';

import {
  ExpandMoreStyled,
  ExpandLessStyled,
  CollapseStyled,
  ListItemTextStyled,
  ListItemButtonStyled,
  ListItemOnClickCaptureStyled,
} from './MenuItemNested.style';
import { MenuItemStyle } from '../../../models/domain/enum';

const MenuItemNested = ({
  link,
  children,
  level,
  onClick,
  open: openLocal,
  useOpenLocal,
  border,
  styleItem = MenuItemStyle.PRIMARY,
}: {
  link: LinkMenuType;
  children: any;
  level: number;
  onClick?: any;
  open?: boolean;
  useOpenLocal?: boolean;
  border?: boolean;
  styleItem?: MenuItemStyle;
}) => {
  const [open, setOpen] = React.useState(useOpenLocal ? useOpenLocal : false);

  const handleClick = () => {
    onClick?.();
    setOpen(!open);
  };

  return (
    <>
      <ListItemButtonStyled
        level={level}
        openLocal={openLocal}
        onClick={handleClick}
        border={border}
      >
        {link.url ? (
          <LinkStyled href={link.url || ''} passHref>
            <ListItemOnClickCaptureStyled
              onClick={() => {}}
              styleItem={styleItem}
            >
              <ListItemTextStyled
                primary={link.text || ''}
                styleItem={styleItem}
              />
            </ListItemOnClickCaptureStyled>
          </LinkStyled>
        ) : (
          <ListItemOnClickCaptureStyled
            onClick={() => {}}
            styleItem={styleItem}
          >
            <ListItemTextStyled
              primary={link.text || ''}
              styleItem={styleItem}
            />
          </ListItemOnClickCaptureStyled>
        )}
        {open ? <ExpandLessStyled /> : <ExpandMoreStyled />}
      </ListItemButtonStyled>
      <CollapseStyled in={open} timeout="auto" unmountOnExit border={border}>
        {children}
      </CollapseStyled>
    </>
  );
};

export default MenuItemNested;
