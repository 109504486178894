import { styled } from '@mui/material';
import Input, { InputStyleType } from '../../components/form/input/Input';

export const OuterWrapper = styled('div')(({ theme, color }) => ({
  backgroundColor: color || theme.colors.newsletterBackground,
  marginTop: theme.spacings.unit(8),
}));

export const InnerWrapper = styled('div')(({ theme }) => ({
  maxWidth: theme.spacings.unit(490),
  margin: '0 auto',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacings.unit(26),
  padding: `${theme.spacings.unit(20)} ${theme.spacings.unit(26)}`,
  [theme.breakpointsMediaQuery.down('md')]: {
    padding: `${theme.spacings.unit(24)} ${theme.spacings.unit(10)}`,
    gap: theme.spacings.unit(30 / 4),
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    paddingRight: theme.spacings.unit(20 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
  },
}));

export const NewsLetterLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: `0 0 calc(50% - ${theme.spacings.unit(26)})`,
  alignItems: 'left',
  justifyContent: 'center',
}));

export const NewsLetterRight = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: `0 0 calc(50% - ${theme.spacings.unit(26)})`,
  alignItems: 'center',
  justifyContent: 'center',
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fonts.utility.size(16),
  fontWeight: 500,
  lineHeight: '120%',
  letterSpacing: theme.spacings.unit(-0.12),
}));

export const SubTitle = styled('div')(({ theme }) => ({
  color: theme.colors.subTitleText,
  fontSize: theme.fonts.utility.size(9),
  fontWeight: 400,
  lineHeight: '160%',
}));

export const MailField = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacings.unit(4),
  gap: theme.spacings.unit(4),
}));

export const MailFieldInput = styled((props: any) => (
  <Input {...props} styleType={InputStyleType.secondary} />
))(({ theme }) => {
  return {
    display: 'flex',
    padding: `${theme.spacings.unit(3.75)} ${theme.spacings.unit(6)}`,
    alignItems: 'flex-start',
    gap: theme.spacings.unit(2),
    flex: '1 0 0',
    borderRadius: theme.spacings.unit(0.25),
    background: theme.colors.secondaryTextWhite,
  };
});

export const SubmitButton = styled('button')(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacings.unit(3.75)} ${theme.spacings.unit(5)}`,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacings.unit(2),
  alignSelf: 'stretch',
  borderRadius: theme.spacings.unit(0.25),
  background: theme.colors.primary,
  boxShadow: `0px ${theme.spacings.unit(1.5)} ${theme.spacings.unit(
    5
  )} ${theme.spacings.unit(-1)} rgba(0, 0, 0, 0.10)`,
  borderWidth: 0,
  '&&:hover': {
    backgroundColor: theme.colors.primaryHover,
    cursor: 'pointer',
  },
  [theme.breakpointsMediaQuery.down('sm')]: {
    padding: `${theme.spacings.unit(3.75)} ${theme.spacings.unit(2.5)}`,
  },
}));

export const ButtonText = styled('span')(({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 900,
  lineHeight: '125%',
  letterSpacing: theme.spacings.unit(0.24),
  textTransform: 'uppercase',
  [theme.breakpointsMediaQuery.down('sm')]: {
    fontSize: theme.fonts.utility.size(5),
    fontWeight: 700,
  },
}));

export const Privacy = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
}));

export const PrivacyText = styled('span')(({ theme }) => ({
  color: theme.colors.subTitleText,
  fontSize: theme.fonts.utility.size(6),
  fontWeight: 400,
  lineHeight: '150%',

  '&& a': {
    color: theme.colors.primary,
  },

  '& p': {
    margin: '0px',
  },
}));

export const ErrorText = styled('span')(({ theme }) => ({
  color: theme.colors.primaryError,
  fontSize: theme.fonts.utility.size(8),
  fontWeight: 400,
  lineHeight: '150%',
}));
