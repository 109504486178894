import {
  defaultInternationalization,
  modalRedirectsLanguagesSirman,
  // modalRedirectsLanguagesFelsinea,
} from 'corporate-types';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '../../assets';
import { TextSize } from '../../models/enum';
import Button, { ButtonStyle } from '../button/Button';

import { CloseButton, MainContainer, StyledLabel } from './ModalLanguage.style';
import { getCompany } from '../../utils/company';
import { Companies } from '../../models/domain/enum';

interface ModalLanguageProps {
  translations: { [key: string]: any };
}

const ModalLanguage: React.FC<ModalLanguageProps> = (
  props: ModalLanguageProps
) => {
  const [userLanguage, setUserLanguage] = useState<string | undefined>(
    undefined
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const { translations } = props;

  let modalRedirectsLanguages: Map<string, string> | undefined = undefined;

  switch (getCompany()) {
    case Companies.SIRMAN:
      modalRedirectsLanguages = modalRedirectsLanguagesSirman;
      break;

      // case Companies.FELSINEA:
      //   modalRedirectsLanguages = modalRedirectsLanguagesFelsinea;
      //   break;

    default:
      break;
  }

  useEffect(() => {
    const lang =
      window?.navigator?.languages?.[0] ||
      window?.navigator?.language ||
      defaultInternationalization;
    setUserLanguage(lang);
    setShowModal(
      lang !== undefined &&
        modalRedirectsLanguages?.get(lang) !== undefined &&
        localStorage.getItem('modalRedirect') !== 'false'
    );
  }, [modalRedirectsLanguages]);

  const handleClick = () => {
    localStorage.setItem('modalRedirect', 'false');
    setShowModal(false);
  };

  return (
    <>
      {showModal && userLanguage && (
        <MainContainer>
          <StyledLabel>
            {translations?.modalLanguage?.label?.[userLanguage]}
          </StyledLabel>
          <Button
            onClick={() =>
              (window.location.href =
                modalRedirectsLanguages?.get(userLanguage) || '')
            }
            styleBtn={ButtonStyle.primary}
            text={translations?.modalLanguage?.button?.[userLanguage]}
            textSize={TextSize.small}
          ></Button>
          <CloseButton onClick={handleClick}>
            <CloseIcon />
          </CloseButton>
        </MainContainer>
      )}
    </>
  );
};

export default ModalLanguage;
