import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

import { SelectType, TextSize } from '../../models/enum';
import { Option } from '../../models/interface';

import {
  CustomContainer,
  CustomSelect,
  CustomLabel,
  StyledLocationIcon,
} from './LocalizationSelect.style';

export interface LocalizationSelectProps {
  values?: string[];
  currentValue: string;
  type?: SelectType;
  disabled?: boolean;
  id?: string;
  onChange: (value: string) => void;
  error?: boolean;
  className?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  options?: Option[];
  textSize?: TextSize;
}

const LocalizationSelect: React.FC<LocalizationSelectProps> = ({
  values,
  currentValue,
  id,
  onChange,
  disabled = false,
  error = false,
  className,
  variant = 'outlined',
  options,
  type,
  textSize,
}: LocalizationSelectProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpointsMediaQuery.up('md'));
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  useEffect(() => {
    setRerender(Date.now());
  }, []);

  return (
    <CustomContainer>
      <CustomSelect
        onChange={onChange}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        currentValue={currentValue}
        id={id}
        disabled={disabled}
        type={type}
        variant={variant}
        error={error}
        className={className}
        disableUnderline={true}
        textSize={textSize}
        values={values}
        options={options}
      />
      <CustomLabel selectType={type}>
        {typeof window === 'undefined' || isDesktop ? currentValue : ''}
        <StyledLocationIcon />
      </CustomLabel>
    </CustomContainer>
  );
};

export default LocalizationSelect;
