import { styled } from '@mui/material';
import { LinkType } from '../../models/domain/interfaces';

export const ModalContentWrapper = styled('div')(({ theme }) => ({
  '& > div': {
    zIndex: 1,
  },
}));

export const HeaderContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '& h3': {
    fontSize: theme.fonts.utility.size(10),
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(
      6.5
    )} ${theme.spacings.unit(4.5)}`,
    '& h3': {
      fontSize: theme.fonts.utility.size(20),
    },
  },
}));

export const Subheader = styled('p')(({ theme }) => ({
  marginBottom: theme.spacings.unit(4),
  color: theme.colors.reservedAreaModalText,
  fontSize: theme.fonts.utility.size(7),
  [theme.breakpointsMediaQuery.up('md')]: {
    marginTop: theme.spacings.unit(7.5),
    fontSize: theme.fonts.utility.size(7),
  },
}));

export const LinksWrapper = styled('div')(({ theme }) => ({
  padding: 0,
  margin: '0 auto',
  [theme.breakpointsMediaQuery.up('sm')]: {
    width: theme.spacings.unit(100),
    maxWidth: theme.spacings.unit(100),
  },
}));

export const Card = styled('div')(({ theme }) => ({
  '&:not(:last-of-type)': {
    paddingBottom: theme.spacings.unit(5),
    borderBottom: `${theme.spacings.unit(1 / 4)} solid ${
      theme.colors.borderInput
    }`,
  },
  '&:not(:first-of-type)': {
    marginTop: theme.spacings.unit(5),
  },
}));

export const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacings.unit(3.25),
  alignItems: 'flex-end',
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(78 / 4),
  width: '60%',
  position: 'relative',
}));

export const CardText = styled('h4')(({ theme }) => ({
  backgroundColor: theme.colors.reservedAreaModalText,
  color: theme.colors.white,
  flexGrow: 1,
  fontSize: theme.fonts.utility.size(10),
  margin: 0,
  textAlign: 'center',
  padding: `${theme.spacings.unit(4.25)} 0`,
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(4)} 0`,
    fontSize: theme.fonts.utility.size(12.5),
  },
}));

export const StyledLink = styled('a', {
  shouldForwardProp: (prop) => !['linkStyle'].includes(prop as string),
})(({ theme, linkStyle }) => {
  const primaryStyle = {
    color: theme.colors.white,
    background: theme.colors.primary,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.colors.primaryHover,
    },
    '& path': {
      fill: theme.colors.white,
    },
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    background: theme.colors.background,
    textDecoration: 'none',
    border: `${theme.spacings.unit(1 / 2)} solid ${theme.colors.primary}`,
    '&:hover': {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
    },
    '& path': {
      fill: theme.colors.primary,
    },
  };

  const typeStyle = (): any => {
    switch (linkStyle) {
      case LinkType.primary:
      default:
        return primaryStyle;
      case LinkType.secondary:
        return secondaryStyle;
    }
  };

  return {
    display: 'block',
    padding: `${theme.spacings.unit(3.25)} ${theme.spacings.unit(5)}`,
    textAlign: 'center',
    fontSize: theme.fonts.utility.size(6),
    ...typeStyle(),
    [theme.breakpointsMediaQuery.up('md')]: {
      fontSize: theme.fonts.utility.size(8),
    },
  };
});
