import React from 'react';
import cookieCutter from 'cookie-cutter';
import { hexToRgb, rgbDataURL } from 'corporate-utils';
import { useRouter } from 'next/router';
import {
  availableInternationalization,
  notCurrentAvailableInternationalization,
  notCurrentAvailableInternationalizationPROD,
} from 'corporate-types';

import ModalWrapper from '../modal/modal-wrapper/ModalWrapper';
import {
  ModalSize,
  ModalStyleType,
  SelectType,
  TextSize,
} from '../../models/enum';
import { SingleStrapiImage } from '../../models/domain/interfaces';
import { getStrapiMedia } from '../../utils/media';

import {
  HeaderContentWrapper,
  ImageWrapper,
  BodyWrapper,
  ModalContentWrapper,
  TransaltionTestStyled,
} from './LocalizationModal.style';
import LocalizationSelect from '../localization-select/LocalizationSelect';
import { LocalizedPath, LocalizedPaths } from '../../models/domain/type';
import { DEFAULT_IMAGE } from '../../models/domain/const';
import ImageWithFallback from '../image-with-fallback/ImageWithFallback';

interface IProps {
  open: boolean;
  handleClose: () => void;
  logo: SingleStrapiImage;
  translations: any;
  localizedPaths: LocalizedPaths;
  locale: string;
  isPrerenderRequest: boolean;
}

type LocalizationProps = IProps;

const LocalizationModal: React.FC<LocalizationProps> = ({
  open,
  handleClose,
  logo,
  translations,
  localizedPaths,
  locale,
  isPrerenderRequest,
}) => {
  const router = useRouter();
  const { query } = router;

  const onChangeLanguage = (language: string) => {
    const path = localizedPaths.find?.(
      (path: LocalizedPath) => path?.locale === language
    ) || {
      href: '/',
    };
    cookieCutter.set('NEXT_LOCALE', language, {
      path: '/',
    });
    if (path.href == '') path.href = '/';
    if (query) {
      delete query?.slug;
    }
    router.push(
      {
        pathname: path.href,
        query: path.href != '/' ? query : null,
      },
      {
        pathname: path.href,
        query: path.href != '/' ? query : null,
      },
      { locale: language }
    );
    handleClose();
  };

  return (
    <ModalWrapper
      show={open}
      hideModal={handleClose}
      size={ModalSize.BIG}
      showDefaultHeader={false}
      styleType={ModalStyleType.localizations}
    >
      <ModalContentWrapper>
        <ModalWrapper.Header typeHeader="content" onClose={handleClose}>
          <HeaderContentWrapper>
            <ImageWrapper>
              <ImageWithFallback
                layout="fill"
                objectFit="contain"
                objectPosition="left"
                placeholder="blur"
                blurDataURL={rgbDataURL(
                  hexToRgb('ffffff')?.[0],
                  hexToRgb('ffffff')?.[1],
                  hexToRgb('ffffff')?.[2]
                )}
                src={getStrapiMedia(logo?.data?.attributes?.url ?? '')}
                alt={logo?.data?.attributes?.alternativeText}
                fallbackSrc={DEFAULT_IMAGE}
                isPrerenderRequest={isPrerenderRequest}
              />
            </ImageWrapper>
          </HeaderContentWrapper>
        </ModalWrapper.Header>
        <ModalWrapper.Body styleType={ModalStyleType.localizations}>
          <BodyWrapper>
            <TransaltionTestStyled>
              {translations?.mobileMenu?.translationIntro}
            </TransaltionTestStyled>
            <LocalizationSelect
              onChange={(val: string | number) =>
                onChangeLanguage(val.toString())
              }
              currentValue={locale}
              options={
                Object.values(availableInternationalization)
                  .filter((value) => {
                    return process.env.NEXT_PUBLIC_ENV === 'PROD'
                      ? Object.values(
                        notCurrentAvailableInternationalizationPROD
                      ).indexOf(value) === -1
                      : Object.values(
                        notCurrentAvailableInternationalization
                      ).indexOf(value) === -1;
                  })
                  .map((internationalization: string) => {
                    return {
                      label:
                        translations?.components?.shell?.header?.languages[
                          internationalization
                        ],
                      value: internationalization,
                    };
                  }) || []
              }
              type={SelectType.secondary}
              textSize={TextSize.small}
            />
          </BodyWrapper>
        </ModalWrapper.Body>
      </ModalContentWrapper>
    </ModalWrapper>
  );
};

export default LocalizationModal;
