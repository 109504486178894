import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { MenuItemStyle } from '../../../models/domain/enum';

export const ExpandMoreStyled = styled(ExpandMore)(() => ({}));
export const ExpandLessStyled = styled(ExpandLess)(() => ({}));

export const CollapseStyled = styled(Collapse as any, {
  shouldForwardProp: (prop) => !['border'].includes(prop as string),
})(({ theme, border }) => ({
  display: 'block',
  '.MuiCollapse-wrapper': {
    display: 'block',
  },
  borderBottom: border ? `1px solid ${theme.colors.drawerDivider}` : 'unset',
}));

export const ListItemTextStyled = styled(ListItemText as any)(
  ({ theme, styleItem }) => ({
    margin: 0,
    fontFamily: theme.fonts.default.bold.fontFamily,
    fontWeight: theme.fonts.default.bold.fontWeight,

    '&& .MuiTypography-root': {
      fontSize:
        styleItem === MenuItemStyle.SECONDARY
          ? theme.spacings.unit(14 / 4)
          : 'inherit',
    },
  })
);

export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop) => !['level', 'openLocal'].includes(prop as string),
})(({ theme, level, openLocal, border }) => ({
  padding: 0,
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: `${theme.spacings.unit(36 / 4)}`,
  paddingLeft: `${theme.spacings.unit((36 / 4) * level)}`,
  borderBottom: border ? `1px solid ${theme.colors.drawerDivider}` : 'unset',
}));

export const ListItemOnClickCaptureStyled = styled('a')(
  ({ theme, styleItem }) => {
    return {
      height: '100%',
      display: 'inline-block',
      color: `${theme?.colors?.primaryText || ''}`,
      cursor: 'pointer',
      textDecoration: 'none',
      paddingBottom:
        styleItem === MenuItemStyle.SECONDARY
          ? `${theme.spacings.unit(6 / 4)}`
          : `${theme.spacings.unit(12 / 4)}`,
      paddingTop:
        styleItem === MenuItemStyle.SECONDARY
          ? `${theme.spacings.unit(6 / 4)}`
          : `${theme.spacings.unit(12 / 4)}`,
      fontFamily: theme.fonts.default.bold.fontFamily,
      fontWeight: theme.fonts.default.bold.fontWeight,
    };
  }
);
