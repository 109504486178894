import * as React from 'react';

import MenuItemNested from '../menu-item-nested/MenuItemNested';
import MenuItemSingle from '../menu-item-single/MenuItemSingle';

import { ListStyled } from './MobileMenu.style';

const MobileMenu = ({
  children,
}: {
  children: JSX.Element | JSX.Element[] | any;
}) => {
  const childs = Array.isArray(children) ? children : [children];

  return (
    <ListStyled component="nav" aria-labelledby="">
      {childs?.map?.((Child, index) => {
        return <Child.type {...Child.props} key={index}></Child.type>;
      })}
    </ListStyled>
  );
};

export default MobileMenu;

export { MenuItemSingle, MenuItemNested };
