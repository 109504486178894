import React from 'react';
import { styled } from '@mui/material';
import { ResetSearch } from '../../assets';

export const Wrapper = styled((props: any) => (
  <div className={props.className}>{props.children}</div>
))(({ theme, disabled }) => {
  return {
    display: 'flex',
    padding: `${theme.spacings.unit(9 / 4)} ${theme.spacings.unit(10 / 4)}`,
    border: `${theme.spacings.unit(0.25)} solid ${theme.colors.borderInput}`,
    backgroundColor: theme.colors.white,
    ['& > svg']: {
      margin: 'auto 0 auto 0',
      fill: theme.colors.searchSimpleImage,
      color: theme.colors.searchSimpleImage,
    },
    cursor: disabled ? 'not-allowed' : 'text',
    ['& > input']: {
      color: disabled ? theme.colors.textDisabled : '',
    },
  };
});

export const InputSearch = styled((props: any) => <input {...props} />)(
  ({ theme }) => {
    return {
      WebkitAppearance: 'none',
      color: theme.colors.primaryText,
      backgroundColor: theme.colors.background,
      padding: `0 0 0 ${theme.spacings.unit(10 / 4)}`,
      margin: 'auto 0 auto 0',
      transition: '0.3s',
      flex: 1,
      fontSize: theme.fonts.utility.size(7),
      lineHeight: theme.fonts.utility.lineHeight(4.5),
      borderEadius: 0,
      border: 0,
      fontFamily: theme.fonts.default.regular.fontFamily,
      ['&:focus,&:hover']: {
        outline: 'none',
        border: 0,
      },
      [' &:focus']: {
        outline: 'none',
      },
      ['::placeholder']: {
        color: theme.colors.inputPlaceholder,
        opacity: 1,
        fontWeight: '600',
      },
      [':-ms-input-placeholder ']: {
        /* Internet Explorer 10-11 */
        color: theme.colors.inputPlaceholder,
      },
      ['::-ms-input-placeholder ']: {
        /* Microsoft Edge */
        color: theme.colors.inputPlaceholder,
      },
      ['&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active ']:
        {
          transition: 'background-color 5000s',
          '-webkit-text-fill-color': theme.colors.onSurface,
        },
    };
  }
);

export const ResetSearchStyled = styled((props: any) => (
  <span className={props.className}>
    <ResetSearch {...props} />
  </span>
))(() => {
  return {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };
});
