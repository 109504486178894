import React, { useEffect, useRef, useState } from 'react';
import { isBrowser } from 'corporate-utils';
import observeRect from '@reach/observe-rect';

import { SpaceStyled, SpaceStyledMaunualTrigger } from './ScrollFixer.style';

const ScrollFixer = () => {
  const [sp, setScrollSpace] = useState<boolean>(true);
  const ro = useRef<{ observe(): void; unobserve(): void } | undefined>(
    undefined
  );
  const spaceStyledMaunualTriggerRef = useRef<Element | undefined>(undefined);

  useEffect(() => {
    if (isBrowser() && !ro.current) {
      const node = isBrowser() && document?.getElementById('__next');
      ro.current = observeRect(node as any, () => {
        const firstDiv = document?.getElementById('__next');

        if (firstDiv && firstDiv.offsetHeight <= window.innerHeight) {
          setScrollSpace(true);
        } else {
          setScrollSpace(false);
        }
      });
    }
    // start observing
    isBrowser() && document && ro.current && ro.current.observe();

    return () => {
      // stop observing
      document && ro.current && ro.current.unobserve();
    };
  }, []);

  useEffect(() => {
    if (isBrowser() && spaceStyledMaunualTriggerRef.current) {
      spaceStyledMaunualTriggerRef.current.id = 'manual-scroll-fixer';
    }
  }, [spaceStyledMaunualTriggerRef]);

  return (
    <span>
      {!!sp && <SpaceStyled></SpaceStyled>}
      <SpaceStyledMaunualTrigger
        ref={spaceStyledMaunualTriggerRef}
      ></SpaceStyledMaunualTrigger>
    </span>
  );
};

export default ScrollFixer;
