import { styled } from '@mui/material';
import SimpleSelect from '../select/Select';

export const ModalContentWrapper = styled('div')(({ theme }) => ({
  '& > div': {
    zIndex: 1,
  },
}));

export const HeaderContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  flexDirection: 'column',
  '& h3': {
    fontSize: theme.fonts.utility.size(10),
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(
      6.5
    )} ${theme.spacings.unit(4.5)}`,
    '& h3': {
      fontSize: theme.fonts.utility.size(20),
    },
  },
}));

export const BodyWrapper = styled('div')(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacings.unit(5),
  [theme.breakpointsMediaQuery.down('lg')]: {
    paddingLeft: theme.spacings.unit(0),
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(32 / 4),
  position: 'relative',
}));

export const TransaltionTestStyled = styled('p')(() => ({
  display: 'inline-block',
}));

export const LocaleSelectStyled = styled((props: any) => (
  <SimpleSelect {...props} />
))(({ theme }) => ({
  maxWidth: theme.spacings.unit(30),
  lineHeight: theme.spacings.unit(16 / 4),
  color: theme.colors.modalTexgtBlackOnWhite,
  '& select': {
    fontSize: 'inherit',
    lineHeight: theme.spacings.unit(24 / 4),
  },
  '& svg': {
    top: theme.spacings.unit(8 / 4),
  },
}));
