import React from 'react';
import { SearchIcon } from '../../assets';

import { Wrapper, InputSearch, ResetSearchStyled } from './SearchSimple.style';

interface IProps {
  placeholder: string;
  search?: string;
  updateSearch: (search: string) => void;
  borderRadius?: number;
  onEnter?: () => void;
  disabled?: boolean;
}

const SearchSimple: React.FC<IProps> = ({
  placeholder,
  search,
  updateSearch,
  borderRadius,
  onEnter,
  disabled = false,
}: IProps) => {
  return (
    <Wrapper disabled={disabled} borderRadius={borderRadius || 0}>
      <SearchIcon />
      <InputSearch
        value={search}
        placeholder={placeholder}
        onChange={(e: any) => {
          updateSearch(e.currentTarget.value);
        }}
        onKeyPress={(event: any) => {
          if (event.key === 'Enter') {
            onEnter ? onEnter() : null;
          }
        }}
        disabled={disabled}
      />
      {search && (
        <ResetSearchStyled
          onClick={() => {
            updateSearch('');
          }}
        />
      )}
    </Wrapper>
  );
};

export default SearchSimple;
