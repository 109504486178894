import { styled } from '@mui/material';
import Button from '../button/Button';

export const StyledContactButton = styled(Button, {
  shouldForwardProp: (prop) => !['start', 'endIcon'].includes(prop as string),
})(({ theme }) => ({
  borderRadius: '4px',
  minWidth: '0',
  height: theme.spacings.unit(54 / 4),
  fontSize: theme.fonts.utility.size(6),
  [theme.breakpointsMediaQuery.up('md')]: {
    fontSize: theme.fonts.utility.size(8),
  },

  [theme.breakpointsMediaQuery.down('lg')]: {
    aspectRatio: '1/1',
  },
}));

export const StyledContainer = styled('span')(({ theme }) => ({
  position: 'fixed',
  right: '20px',
  bottom: '20px',
  zIndex: '2',
  display: 'flex',
  paddingLeft: 'env(safe-area-inset-left)',
  paddingRight: 'env(safe-area-inset-right)',
  '@media print': {
    display: 'none',
  },

  [theme.breakpointsMediaQuery.down('lg')]: {
    bottom: '80px',
  },

  svg: {
    display: 'block',
  },
}));

export const ScrollFixerForButton = styled('span')(() => ({
  overflow: 'scroll',
  visibility: 'hidden',
  flex: 'none',
}));
