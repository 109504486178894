import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import {
  styled,
  ListSubheader,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { MenuItemStyle } from '../../../models/domain/enum';

export const ListItemIconStyled = styled(ListItemIcon)(() => ({})); // non usato
export const InboxIconStyled = styled(InboxIcon)(() => ({})); // non usato
export const ListSubheaderStyled = styled(ListSubheader)(() => ({})); // non usato
export const StarBorderStyled = styled(StarBorder)(() => ({})); // non usato
export const SendIconStyled = styled(SendIcon)(() => ({})); // non usato
export const DraftsIconStyled = styled(DraftsIcon)(() => ({})); // non usato
export const ExpandMoreStyled = styled(ExpandMore)(() => ({})); // non usato
export const ExpandLessStyled = styled(ExpandLess)(() => ({})); // non usato
export const CollapseStyled = styled(Collapse)(() => ({})); // non usato
export const ListStyled = styled(List)(() => ({})); // non usato

export const ListItemTextStyled = styled(ListItemText as any)(
  ({ theme, styleItem }) => ({
    margin: 0,
    fontFamily: theme.fonts.default.bold.fontFamily,
    fontWeight: theme.fonts.default.bold.fontWeight,

    '&& .MuiTypography-root': {
      fontSize:
        styleItem === MenuItemStyle.SECONDARY
          ? theme.spacings.unit(14 / 4)
          : 'inherit',
    },
  })
);
export const ListItemButtonStyled = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    !['styleBtn', 'openLocal'].includes(prop as string),
})(({ theme, level, openLocal }) => ({
  padding: 0,
  height: '100%',
  paddingRight: theme.spacings.unit(36 / 4),
  paddingLeft: `${theme.spacings.unit((36 / 4) * level)}`,
  // Ugly
  // backgroundColor: (() => {
  //   switch (true) {
  //     case level === 1 && openLocal:
  //       return '#E30813';
  //     case level === 2 && openLocal:
  //       return '#e308133b';
  //     case level === 3 && openLocal:
  //       return '#e3081321';
  //     default:
  //       return 'inherit';
  //   }
  // })(),
  // color: (() => {
  //   switch (true) {
  //     case level === 1 && openLocal:
  //       return 'white';
  //     default:
  //       return 'inherit';
  //   }
  // })(),
  // 'a': {
  //   color: (() => {
  //     switch (true) {
  //       case level === 1 && openLocal:
  //         return 'white';
  //       default:
  //         return 'inherit';
  //     }
  //   })()
  // }
}));
export const ListItemOnClickCaptureStyled = styled('a')(
  ({ theme, styleItem }) => {
    return {
      width: '100%',
      height: '100%',
      display: 'inline-block',
      color: `${theme?.colors?.primaryText || ''}`,
      cursor: 'pointer',
      textDecoration: 'none',
      paddingBottom:
        styleItem === MenuItemStyle.SECONDARY
          ? `${theme.spacings.unit(6 / 4)}`
          : `${theme.spacings.unit(12 / 4)}`,
      paddingTop:
        styleItem === MenuItemStyle.SECONDARY
          ? `${theme.spacings.unit(6 / 4)}`
          : `${theme.spacings.unit(12 / 4)}`,
      fontFamily: theme.fonts.default.bold.fontFamily,
      fontWeight: theme.fonts.default.bold.fontWeight,
    };
  }
);
