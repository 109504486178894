import React, { ReactChild, useState } from 'react';
import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';

import Footer from '../shell/footer/Footer';
import Header from '../shell/header/Header';
import ContactButton, { ButtonStyle } from '../contact-button/ContactButton';
import { ContentWrapper } from '../../styles/shared';
import { SnackbarActions, useSnackbar } from '../../stores/snackbar';
import {
  HeaderContainer,
  MainContainer,
  Wrapper,
  MainStyled,
  StyleContactButton,
  ContactTextStyled,
  TextContainerStyled,
  SnackbarStyled,
} from './Layout.style';
import ScrollFixer from '../scroll-fixer/ScrollFixer';
import { ChatBubbleOutlineIcon } from '../../assets';
import BottomNavbar from '../shell/bottom-navbar/BottomNavbar';
import ModalLanguage from '../modal-language/ModalLanguage';
import { LocalizedPaths } from '../../models/domain/type';
import { getCompany } from '../../utils/company';
import { Companies } from '../../models/domain/enum';
import HeaderFls from '../shell/header-fls/HeaderFls';
import { UserContext } from '../../models/interface';

// Components
const ContactModal = dynamic(() => import('../contact-modal/ContactModal'));

interface LayoutProps {
  children: ReactChild[] | ReactChild;
  pageContext: NextPageContext & {
    slug: string;
    localizedPaths: LocalizedPaths;
  };
  global: any;
  translations: { [key: string]: any };
  userContext: UserContext;
  isPrerenderRequest: boolean;
  productName: string;
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {
  const {
    global,
    children,
    translations,
    pageContext,
    userContext,
    productName,
  } = props;
  const { header, footer } = global.attributes || {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { state, dispatch } = useSnackbar();
  const { show, message, alertSeverity } = state;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <MainContainer>
      <Wrapper>
        <HeaderContainer>
          <ModalLanguage translations={translations} />
          {getCompany() === Companies.SIRMAN ? (
            <Header
              {...header}
              isPrerenderRequest={props.isPrerenderRequest}
              translations={translations}
              locale={pageContext.locale}
              localizedPaths={pageContext.localizedPaths}
              userCountry={userContext?.country}
            />
          ) : (
            <></>
          )}
          {getCompany() === Companies.FELSINEA ? (
            <HeaderFls
              {...header}
              isPrerenderRequest={props.isPrerenderRequest}
              translations={translations}
              locale={pageContext.locale}
              localizedPaths={pageContext.localizedPaths}
              userCountry={userContext?.country}
            />
          ) : (
            <></>
          )}
        </HeaderContainer>
        <ContentWrapper>
          <ContactButton
            text={
              <StyleContactButton>
                <TextContainerStyled>
                  <ContactTextStyled>
                    {translations?.pages?.layout.contactButton}
                  </ContactTextStyled>
                  <ChatBubbleOutlineIcon />
                </TextContainerStyled>
              </StyleContactButton>
            }
            styleBtn={ButtonStyle.primary}
            uppercase={true}
            onClick={handleOpenModal}
          />
        </ContentWrapper>
        {isModalOpen && (
          <ContactModal
            open={isModalOpen}
            translations={translations}
            locale={pageContext.locale}
            handleClose={handleCloseModal}
            privacyLink={footer?.privacyLink}
            privacyText={footer?.privacyText}
            userCountry={userContext?.country}
            reason={
              productName
                ? `${translations?.pages?.productDetails?.informationReason} ${productName}`
                : ''
            }
          />
        )}
        {show && (
          <SnackbarStyled
            open={show}
            onClose={() =>
              dispatch({
                type: SnackbarActions.HIDE,
              })
            }
            alertSeverity={alertSeverity}
            message={message}
          />
        )}
        <MainStyled>{children}</MainStyled>
        <Footer
          {...footer}
          translations={translations}
          mainLinks={header?.mainLinks}
          megamenuLinks={header?.megamenuLinks}
        />
        <BottomNavbar {...header}></BottomNavbar>
      </Wrapper>
      <ScrollFixer></ScrollFixer>
    </MainContainer>
  );
};

export default Layout;
