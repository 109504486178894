import app from '../../package.json';

export const getFEVersion = (): string => {
  return app.version;
};

export const isDevelopmentEnv = (): string | boolean => {
  const env = process.env.NEXT_PUBLIC_ENV ?? 'N/A';
  if (env === 'PROD') return false;
  return env;
};

export const getCurrentBuildVersion = (): string => {
  return process.env.BUILD_VERSION ?? 'N/A';
};
