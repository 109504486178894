import { styled } from '@mui/system';

export const MainContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  minHeight: theme.spacings.unit(60 / 4),
  backgroundColor: theme.colors.secondaryBackground,
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacings.unit(20 / 4),
  padding: theme.spacings.unit(12 / 4),
  borderBottom: `${theme.spacings.unit(0.25)} solid ${
    theme.colors.drawerDivider
  }`,
}));

export const StyledLabel = styled('p')(({ theme }) => ({
  color: theme.colors.secondaryText,
  fontSize: '.8rem',
  margin: 0,
}));

export const CloseButton = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  width: theme.spacings.unit(24 / 4),
  height: theme.spacings.unit(24 / 4),
}));
