import { Alert } from '@mui/material';
import { Snackbar as MuiSnackbar } from '@mui/material';
import React, { ReactChildren, ReactElement } from 'react';
import { AlertSeverity } from '../../models/enum';

export interface SnackbarProps {
  open: boolean;
  autoHideDuration?: number;
  onClose: () => void;
  alertSeverity: AlertSeverity;
  message: string | ReactChildren | ReactElement;
  style?: any;
}

const Snackbar: React.FC<SnackbarProps> = ({
  open,
  onClose,
  alertSeverity,
  autoHideDuration = 6000,
  message,
  style,
}) => {
  return (
    <MuiSnackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      sx={style}
    >
      <Alert onClose={onClose} severity={alertSeverity}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

export default Snackbar;
