import SimpleSelect from '../select/Select';
import { SelectType, TextSize } from '../../models/enum';
import { styled } from '@mui/material';
import { LocationOnIcon } from '../../assets';

export const CustomContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  minWidth: 'max-content',
  maxWidth: 'max-content',
  display: 'inline-flex',
}));

export const CustomLabel = styled('label', {
  shouldForwardProp: (prop) =>
    prop !== 'textSize' && prop !== 'selectType' && prop !== 'textSize',
})(({ theme, selectType, disabled, textSize }) => {
  selectType = disabled ? 'disabled' : selectType;

  const disabledStyle = {
    color: theme.colors.textDisabled,
    fill: theme.colors.disabled,
    background: theme.colors.disabled,
  };

  const primaryStyle = {
    color: theme.colors.white,
    fill: theme.colors.white,
    background: theme.colors.primary,
  };

  const secondaryStyle = {
    color: theme.colors.primary,
    fill: theme.colors.primary,
    background: theme.colors.background,
  };

  const thirdStyle = {
    color: theme.colors.secondaryText,
    fill: theme.colors.primary,
    background: theme.colors.white,
  };

  const smallStyle = {
    fontSize: '0.8rem',
  };

  const mediumStyle = {
    fontSize: theme.fonts.utility.size(12.8 / 2),
  };

  const largeStyle = {
    fontSize: '1.2rem',
  };

  const typeStyle = () => {
    switch (selectType) {
      case SelectType.primary:
      default:
        return primaryStyle;
      case SelectType.secondary:
        return secondaryStyle;
      case SelectType.third:
        return thirdStyle;
      case SelectType.disabled:
        return disabledStyle;
    }
  };

  const sizeStyle = () => {
    switch (textSize) {
      case TextSize.small:
        return smallStyle;
      case TextSize.medium:
      default:
        return mediumStyle;
      case TextSize.large:
        return largeStyle;
    }
  };

  return {
    fontSize: sizeStyle().fontSize,
    borderRadius: 0,
    backgroundColor: typeStyle().background,
    borderColor: theme.colors.borderInput,
    paddingLeft: 0,
    padding: `${theme.spacings.unit(1)} ${theme.spacings.unit(
      2
    )} ${theme.spacings.unit(1)} ${theme.spacings.unit(4)}`,
    [theme.breakpointsMediaQuery.down('lg')]: {
      padding: `${theme.spacings.unit(1)}`,
    },
    marginBottom: 0,
    color: typeStyle().color,
    display: 'inline-flex',
    alignItems: 'center',
    gap: theme.spacings.unit(4 / 4),

    '&:focus': {
      borderRadius: 0,
      backgroundColor: typeStyle().background,
    },

    '& path': {
      fill: typeStyle().fill,
    },

    '& svg': {
      top: theme.spacings.unit(1),
      right: theme.spacings.unit(1),
    },

    '&:hover': (typeStyle() as any)?.['&:hover']
      ? (typeStyle() as any)?.['&:hover']
      : {},
  };
});

export const CustomSelect = styled((props: any) => <SimpleSelect {...props} />)(
  () => ({
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    height: '100%',
    width: '100%',
  })
);

export const StyledLocationIcon = styled(LocationOnIcon)(() => ({
  width: '0.8em',
  height: '0.8em',
}));
