import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { TextSize } from '../../models/enum';

import {
  IconWrapper,
  LinkVoice,
  LinkVoiceNoA,
  LinkVoiceTextStyled,
} from './LinkAction.style';

interface IProps {
  href?: string;
  text: string;
  title?: string;
  onClick?: () => void;
  onMouseOver?: (e?: any) => void;
  onMouseLeave?: (e: any, touch?: boolean) => void;
  size?: TextSize;
  type?: LinkType;
  icon?: React.ReactElement;
  noClick?: boolean;
  isActive?: 'active' | '';
  identify?: string | number;
  hover?: any | undefined;
  exitHover?: any | undefined;
}

export enum LinkType {
  'primary',
  'secondary',
  'header',
  'headerSecondary',
}

const LinkAction: React.FC<IProps> = ({
  href,
  onClick,
  size,
  type,
  text,
  title,
  icon,
  isActive,
}: IProps) => {
  const router = useRouter();

  return href ? (
    <Link href={href as string} passHref>
      <LinkVoice
        onClick={onClick}
        title={title || text}
        type={type}
        size={size}
      >
        <LinkVoiceTextStyled
          type={type}
          isActive={isActive || (router.asPath === href ? 'active' : '')}
        >
          {text}
        </LinkVoiceTextStyled>
        {icon ? <IconWrapper>{icon}</IconWrapper> : null}
      </LinkVoice>
    </Link>
  ) : (
    <LinkVoiceNoA
      onClick={onClick}
      title={title || text}
      type={type}
      size={size}
    >
      <LinkVoiceTextStyled
        type={type}
        isActive={isActive || (router.asPath === href ? 'active' : '')}
      >
        {text}
      </LinkVoiceTextStyled>
      {icon ? <IconWrapper>{icon}</IconWrapper> : null}
    </LinkVoiceNoA>
  );
};

export default LinkAction;
