import { styled } from '@mui/material';
import { TextSize } from '../../models/enum';
import { LinkType } from './LinkActionSubmenuFls';

const linkVoiceBaseStyle = ({ theme, type, size, removePaddingRight }: any) => {
  let typeStyle = {};
  let sizeStyle = {};

  switch (size) {
    case TextSize.small:
      sizeStyle = {
        fontSize: '.8rem',
      };
      break;
    case TextSize.medium:
    default:
      sizeStyle = {
        fontSize: '1rem',
      };
      break;
    case TextSize.large:
      sizeStyle = {
        fontSize: '1.2rem',
      };
      break;
  }

  switch (type) {
    case LinkType.header:
    default:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacings.unit(30 / 4),
        paddingRight: theme.spacings.unit(5),
        cursor: 'pointer',
        backgroundColor: 'transparent',
        width: 'auto',
        paddingLeft: 'initial',
        color: theme.colors.secondaryText,
        textTransform: 'none',
        '& svg': {
          color: theme.colors.primary,
        },
        height: '100%',
        ...(removePaddingRight
          ? {
            paddingRight: 0,
          }
          : {}),
      };
      break;
    case LinkType.headerSecondary:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.secondaryText,
        textTransform: 'capitalize',
        '& svg': {
          color: theme.colors.primary,
        },
        '&:hover': {
          color: theme.colors.primary,
        },
      };
      break;
    case LinkType.felsineaMobile:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        color: theme.colors.secondaryText,
        textTransform: 'capitalize',
        '& svg': {
          color: theme.colors.primary,
        },
        '&:hover': {
          color: theme.colors.primary,
        },
        width: 'auto',
        margin: '4px 36px',
        gap: '10px',
        border: `1px solid ${theme.colors.primary}`,
        borderRadius: '6px',
        padding: theme.spacings.unit(2),
        'span:nth-child(3)': {
          marginLeft: 'auto',
        },
      };
      break;
  }

  return {
    touchAction: 'pan-y',
    ...typeStyle,
    ...sizeStyle,
    position: 'relative',
    textDecoration: 'none',
    ':hover': {
      '.link_submenu:not(:empty)': {
        display: 'block',
        boxShadow: theme.gradients.modal,
      },
    },
  };
};

export const LinkVoice = styled('a', {
  shouldForwardProp: (prop) =>
    !['size', 'type', 'removePaddingRight'].includes(prop as string),
})(linkVoiceBaseStyle as any);

export const LinkVoiceNoA = styled('span', {
  shouldForwardProp: (prop) =>
    !['size', 'type', 'removePaddingRight'].includes(prop as string),
})(linkVoiceBaseStyle as any);

export const LinkVoiceTextStyled = styled('span', {
  shouldForwardProp: (prop) =>
    ![
      'isActive',
      'type',
      'href',
      'mobileFontSize',
      'fontSize',
      'mobileFontWeight',
      'fontWeight ',
    ].includes(prop as string),
})(
  ({
    theme,
    isActive,
    type,
    href,
    mobileFontSize,
    fontSize,
    mobileFontWeight,
    fontWeight,
  }) => {
    let typeStyle: {
      [key: string]: any;
    } = {
      [theme.breakpointsMediaQuery.up('sm')]: {
        fontSize: fontSize ? `${fontSize}px` : 'inherit',
        lineHeight: fontSize ? `${fontSize + 4}px` : 'inherit',
        fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
      },
      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: mobileFontSize ? `${mobileFontSize}px` : 'inherit',
        lineHeight: mobileFontSize ? `${mobileFontSize}px` : 'inherit',
        fontWeight: mobileFontWeight ? mobileFontWeight : 'inherit',
      },
    };

    switch (type) {
      case LinkType.header:
      default:
        if (isActive === 'active' && type === LinkType.header) {
          typeStyle = {
            ...typeStyle,
            color: theme.colors.secondaryText,
            borderBottom: `${theme.spacings.unit(0.5)} solid ${
              theme.colors.primary
            }`,
          };
        }
        return {
          margin: '0',
          borderBottom: `${theme.spacings.unit(0.5)} solid transparent`,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginTop: theme.spacings.unit(0.5),
          overflow: 'hidden',
          ...typeStyle,
          ...(href
            ? {
              '&:hover': {
                color: theme.colors.primary,
                borderBottom: `${theme.spacings.unit(0.5)} solid ${
                  theme.colors.primary
                }`,
              },
            }
            : {}),
        };
    }
  }
);

export const SubLinkVoiceTextStyled = styled(LinkVoiceTextStyled)(
  ({ theme }) => {
    return {
      width: '100%',
      color: theme.colors.secondaryText,
      '&:hover': {
        color: theme.colors.primary,
        borderBottom: 0,
        backgroundColor: theme.colors.white,
      },
      borderBottom: 0,
      padding: `${theme.spacings.unit(3)}}`,
    };
  }
);

export const IconWrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'text',
})(({ theme }) => {
  return {
    height: 'auto',
    display: 'flex',
    '& svg': {
      width: '100%',
      height: '100%',
    },
    '& .svgInline': {
      display: 'block',
      height: `${theme.spacings.unit(6)}}`,
      width: `${theme.spacings.unit(6)}}`,
    },
  };
});

export const SubmenuContainer = styled('span', {
  shouldForwardProp: (prop) => ![''].includes(prop as string),
})(({ theme }) => {
  return {
    position: 'absolute',
    top: '100%',
    display: 'none',
    padding: `${theme.spacings.unit(1)}`,
    right: '0',
    backgroundColor: theme.colors.white,
    minWidth: theme.spacings.unit(30),
    zIndex: 1,
    marginTop: theme.spacings.unit(0.25),
    span: {
      marginTop: '0',
    },
  };
});
