import React, { useEffect, useState } from 'react';

import { SelectType, TextSize } from '../../models/enum';
import { Option } from '../../models/interface';

import {
  CustomContainer,
  CustomSelect,
  CustomLabel,
  StyledLocationIcon,
} from './LocalizationSelectFls.style';
import { KeyboardArrowDown } from '../../assets';

export interface LocalizationSelectProps {
  values?: string[];
  currentValue: string;
  type?: SelectType;
  disabled?: boolean;
  id?: string;
  onChange: (value: string) => void;
  error?: boolean;
  className?: string;
  variant?: 'outlined' | 'filled' | 'standard';
  options?: Option[];
  textSize?: TextSize;
}

const LocalizationSelectFls: React.FC<LocalizationSelectProps> = ({
  values,
  currentValue,
  id,
  onChange,
  disabled = false,
  error = false,
  className,
  variant = 'outlined',
  options,
  type,
  textSize,
}: LocalizationSelectProps) => {
  const [, setRerender] = useState<number>(0); // Trick to force re-render

  useEffect(() => {
    setRerender(Date.now());
  }, []);

  return (
    <CustomContainer>
      <CustomSelect
        onChange={onChange}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        currentValue={currentValue}
        id={id}
        disabled={disabled}
        type={type}
        variant={variant}
        error={error}
        className={className}
        disableUnderline={true}
        textSize={textSize}
        values={values}
        options={options}
      />
      <CustomLabel selectType={type}>
        <StyledLocationIcon />
        {currentValue}
        <KeyboardArrowDown />
      </CustomLabel>
    </CustomContainer>
  );
};

export default LocalizationSelectFls;
