import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { defaultInternationalization, defaultLanguage } from 'corporate-types';
import { internationalizationToLanguages } from 'corporate-utils';

import {
  LoadingSection,
  LoadingResults,
  NoSuggestionContainer,
  NoSuggestionDescription,
  NoSuggestionLogo,
  NoSuggestionTitle,
  ResultSectionListItemStyled,
  ResultSectionListStyled,
  ResultSectionStyled,
  ResultSectionTitleStyled,
  ResultStyled,
  ResultWrapperStyled,
  SectionHint,
  TextStyle,
  WrapperStyled,
  LoadingRow,
} from './Autocomplete.style';
import SearchSimple from '../search-simple/SearchSimple';
import { useTheme } from '../../theme';
import { Binoculars, HelpOutlineOutlinedIcon } from '../../assets';
import { getTranslations } from '../../utils/localize';

export interface ListSearch {
  [key: string]: {
    text: string;
    value: string;
    parents?: number[];
  }[];
}

interface IProps {
  loading?: boolean;
  results?: ListSearch;
  search?: string;
  placeholder?: string;
  updateSearch: (search: string) => void;
  onClickSuggestion: (
    id: string,
    type: string,
    parents?: number[],
    text?: string
  ) => void;
  useAlternativeHint?: boolean;
}

const Autocomplete: React.FC<IProps> = ({
  results,
  search,
  updateSearch,
  placeholder,
  onClickSuggestion,
  loading,
  useAlternativeHint = false,
}: IProps) => {
  const { state } = useTheme();
  const [focus, setFocus] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  const [isFirstSearch, setFirstSearch] = useState<boolean>(true);
  const [translations, setTranslations] = useState({} as any);
  const { theme } = state;

  const router = useRouter();

  const getTranlationCallback = useCallback(async () => {
    const languages = await getTranslations(
      router.locale !== 'default'
        ? internationalizationToLanguages(
          router.locale || defaultInternationalization,
          router?.asPath
        ) || defaultLanguage
        : defaultLanguage
    );
    setTranslations(languages);
  }, [router?.asPath, router.locale]);

  useEffect(() => {
    if (!router.isReady) return;
    getTranlationCallback();
  }, [getTranlationCallback, router.isReady, router.locale]);

  const onClick = async (
    id: string,
    type: string,
    parents?: number[],
    text?: string
  ): Promise<void> => {
    onClickSuggestion(id, type, parents, text);
    setFocus(false);
  };

  let thereIsResults = false;

  results &&
    Object.keys(results as ListSearch).forEach((key: string) => {
      thereIsResults =
        !!thereIsResults || !!(results[key] && results[key].length);
    });

  if (results !== undefined && search && search.length >= 3 && isFirstSearch)
    setFirstSearch(false);
  if (search && search.length < 3 && !isFirstSearch) setFirstSearch(true);

  useEffect(() => {
    setIsSafari(
      window
        ? /constructor/i?.test?.((window as any)?.HTMLElement) ||
            (function (p) {
              return p?.toString?.() === '[object SafariRemoteNotification]';
            })(
              !(window as any)?.['safari'] ||
                (typeof (window as any)?.['safari'] !== 'undefined' &&
                  (window as any)?.['safari']?.pushNotification)
            )
        : false
    );
  }, []);

  return (
    <WrapperStyled
      onFocus={() => setFocus(true)}
      onClick={() => (!focus ? setFocus(true) : null)}
      onBlur={() => setFocus(false)}
    >
      <SearchSimple
        search={search}
        placeholder={placeholder || ''}
        updateSearch={updateSearch}
        borderRadius={theme?.borders?.autocomplete}
        onEnter={() => {
          search ? onClick(search, 'manualSearch') : null;
        }}
      />
      {focus && search && (search || (results && thereIsResults)) && (
        <ResultWrapperStyled>
          <ResultStyled>
            {search && (
              <ResultSectionStyled>
                <ResultSectionListStyled>
                  <ResultSectionListItemStyled
                    onMouseDown={(e: any) => e.preventDefault()}
                    onClick={() => onClick(search, 'manualSearch')}
                  >
                    {translations?.components?.autocomplete?.searchFor} {search}
                  </ResultSectionListItemStyled>
                </ResultSectionListStyled>
              </ResultSectionStyled>
            )}
            {loading && !thereIsResults && (
              <LoadingResults>
                <LoadingSection />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingSection />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
              </LoadingResults>
            )}
            {results &&
              search &&
              search.length >= 3 &&
              thereIsResults &&
              Object.keys(results as ListSearch)?.map((key: string) => {
                const sectionResults = results[key];

                return sectionResults.length ? (
                  <ResultSectionStyled key={key}>
                    <ResultSectionTitleStyled>
                      {
                        translations?.components?.autocomplete[
                          key as keyof typeof translations.components.autocomplete
                        ]
                      }
                    </ResultSectionTitleStyled>
                    <ResultSectionListStyled>
                      {sectionResults?.map(({ text, value, parents }) => (
                        <ResultSectionListItemStyled
                          isSafari={isSafari}
                          key={value}
                          onMouseDown={(e: any) => e.preventDefault()}
                          onClick={() => onClick(value, key, parents, text)}
                          title={text}
                        >
                          {text}
                        </ResultSectionListItemStyled>
                      ))}
                    </ResultSectionListStyled>
                  </ResultSectionStyled>
                ) : (
                  <React.Fragment />
                );
              })}
            {search &&
              search.length >= 3 &&
              !thereIsResults &&
              !loading &&
              !isFirstSearch && (
              <NoSuggestionContainer>
                <NoSuggestionLogo>
                  <Binoculars />
                </NoSuggestionLogo>
                <NoSuggestionTitle styleText={TextStyle.SECONDARY}>
                  {
                    translations?.components?.autocomplete?.noSuggestion
                      ?.title
                  }
                </NoSuggestionTitle>
                <NoSuggestionDescription styleText={TextStyle.TERTIARY}>
                  {
                    translations?.components?.autocomplete?.noSuggestion
                      ?.description
                  }
                </NoSuggestionDescription>
              </NoSuggestionContainer>
            )}
          </ResultStyled>
          {search && (
            <SectionHint>
              <HelpOutlineOutlinedIcon />
              {useAlternativeHint
                ? translations?.components?.autocomplete?.alternativeHint
                : translations?.components?.autocomplete?.hint}
            </SectionHint>
          )}
        </ResultWrapperStyled>
      )}
    </WrapperStyled>
  );
};

export default Autocomplete;
