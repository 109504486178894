import { styled } from '@mui/system';
import Snackbar from '../snackbar/Snackbar';

export const HeaderContainer = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '0px',
  backgroundColor: theme.colors.white,
  zIndex: 1000,
}));

export const Wrapper = styled('div')(() => ({
  position: 'relative',
  flex: 1,
}));

export const MainContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  fontFamily: theme.fonts.default.regular.fontFamily,
}));

export const MainStyled = styled('main')(() => ({
  width: '100vw',
}));

export const StyleContactButton = styled('div')(() => ({
  padding: '1rem 0',
}));

export const RotateIconStyled = styled('div')(() => ({
  transform: 'rotate(180deg)',
  display: 'flex',
  paddingBottom: '0.5rem',
}));

export const ContactTextStyled = styled('div')(({ theme }) => ({
  [theme.breakpointsMediaQuery.down('lg')]: {
    display: 'none',
  },
}));

export const TextContainerStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacings.unit(4),
}));

export const SnackbarStyled = styled((props: any) => (
  <Snackbar
    {...props}
    style={{
      bottom: {
        xs: '88px',
        lg: '24px',
      },
    }}
  />
))({});
