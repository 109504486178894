import { styled } from '@mui/material';

export const WrapperStyled = styled('div')(() => {
  return {
    position: 'relative',
    overflow: 'visible',
    width: '100%',
  };
});

export const ResultWrapperStyled = styled('div')(({ theme }) => {
  return {
    maxHeight: '700px',
    margin: `${theme.spacings.unit(1)} 0 0 0`,
    position: 'absolute',
    overflow: 'auto',
    width: '100%',
    zIndex: 1101,
    borderRadius: `${theme.spacings.unit(1)}`,
    boxShadow: theme.gradients.autocomplete,
    backgroundColor: theme.colors.white,
    border: `${theme.borders.unit(1)} solid ${
      theme.colors.autocompleteBorders
    }`,
  };
});

export const ResultStyled = styled('ul')(({ theme }) => {
  return {
    listStyle: 'none',
    padding: `${theme.spacings.unit(1)} ${theme.spacings.unit(
      12 / 4
    )} ${theme.spacings.unit(1)} ${theme.spacings.unit(12 / 4)}`,
    margin: 0,
  };
});

export const ResultSectionStyled = styled('li')(({ theme }) => {
  return {};
});

export const ResultSectionTitleStyled = styled('p')(({ theme }) => {
  return {
    color: theme.colors.autocompleteCategoryTitle,
    font: theme.fonts.default.bold,
    fontSize: theme.fonts.utility.size(6),
    lineHeight: theme.fonts.utility.lineHeight(4),
  };
});

export const ResultSectionListStyled = styled('ol')(({ theme }) => {
  return {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  };
});

export const ResultSectionListItemStyled = styled('li', {
  shouldForwardProp: (prop) => !['isSafari'].includes(prop as string),
})(({ theme, isSafari }) => {
  return {
    color: theme.colors.autocompleteText,
    padding: theme.spacings.unit(6 / 4),
    font: theme.fonts.default.regular,
    fontSize: theme.fonts.utility.size(14 / 2),
    lineHeight: theme.fonts.utility.lineHeight(5),
    letterSpacing: 0,
    textAlign: 'left',
    borderRadius: theme.borders.unit(4),
    flex: 1,
    direction: 'rtl',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: !isSafari ? 'ellipsis' : 'clip',
    ['&:hover']: {
      color: theme.colors.autocompleteTextHover,
      backgroundColor: theme.colors.autocompleteItemBackgroundHover,
      cursor: 'pointer',
    },
  };
});

export const SectionHint = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.autocompleteHintBackground,
  minHeight: theme.spacings.unit(9),
  padding: `${theme.spacings.unit(2)} ${theme.spacings.unit(3)}`,
  color: theme.colors.autocompleteHintColor,
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacings.unit(8 / 4),
  fontSize: theme.fonts.utility.size(7),
  svg: {
    flexShrink: 0,
    width: theme.spacings.unit(5),
  },
}));

export const NoSuggestionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: `${theme.spacings.unit(2)} ${theme.spacings.unit(5)}`,
  gap: theme.spacings.unit(2),
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingLeft: theme.spacings.unit(10),
    paddingRight: theme.spacings.unit(10),
  },
}));

export const NoSuggestionLogo = styled('div')(({ theme }) => ({
  borderRadius: '50%',
  backgroundColor: theme.colors.autocompleteNotFoundBackground,
  border: `1px solid ${theme.colors.primary}`,
  padding: theme.spacings.unit(2.25),
  minWidth: 'unset',
  aspectRatio: '1/1',
  width: theme.spacings.unit(12),
  svg: {
    width: theme.spacings.unit(25 / 4),
    height: theme.spacings.unit(25 / 4),
    position: 'relative',
    left: '50%',
    top: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    'path:not(:first-of-type), line, circle': {
      stroke: theme.colors.primary,
    },
  },
}));

export enum TextStyle {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  ERROR = 'error',
}

export const Text = styled('span', {
  shouldForwardProp: (prop) => !['styleText'].includes(prop as string),
})(({ theme, styleText }) => ({
  whiteSpace: 'pre-wrap',
  color: (() => {
    switch (styleText) {
      case TextStyle.PRIMARY:
        return theme.colors.primaryText;
      case TextStyle.SECONDARY:
        return theme.colors.secondaryText;
      case TextStyle.TERTIARY:
        return theme.colors.forthText;
      case TextStyle.ERROR:
        return theme.colors.primaryError;
      default:
        return theme.colors.secondaryText;
    }
  })(),
}));

export const NoSuggestionTitle = styled((props: any) => (
  <div>
    <Text {...props} />
  </div>
))(({ theme }) => ({
  textAlign: 'center',
  fontWeight: theme.fonts.default.semiBold.fontWeight,
}));

export const NoSuggestionDescription = styled((props: any) => (
  <div>
    <Text {...props} />
  </div>
))(({ theme }) => ({
  textAlign: 'center',
  fontWeight: theme.fonts.default.regular.fontWeight,
}));

export const LoadingResults = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.unit(2),
  margin: `${theme.spacings.unit(3)} 0`,
}));

export const LoadingSection = styled('div')(({ theme }) => ({
  animationDuration: '0.8s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationName: 'placeHolderShimmer',
  animationTimingFunction: 'linear',
  background: 'linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 84%)',
  backgroundSize: '1000px 640px',
  height: theme.spacings.unit(6),
  maxWidth: theme.spacings.unit(50),
}));

export const LoadingRow = styled('div')(({ theme }) => ({
  animationDuration: '0.8s',
  animationFillMode: 'forwards',
  animationIterationCount: 'infinite',
  animationName: 'placeHolderShimmer',
  animationTimingFunction: 'linear',
  background: 'linear-gradient(to right, #fafafa 8%, #f4f4f4 38%, #fafafa 84%)',
  backgroundSize: '1000px 640px',
  height: theme.spacings.unit(5),
  marginLeft: theme.spacings.unit(2),
  maxWidth: theme.spacings.unit(140),
}));
