import {
  styled,
  BottomNavigation,
  BottomNavigationAction,
} from '@mui/material';

export const BottomNavbarWrapper = styled('div')(({ theme }) => ({
  zIndex: 4000,
  position: 'fixed',
  bottom: 0,
  width: '100%',
  boxShadow: '0px -1px 0 rgb(0 0 0 / 45%)',
  background: theme.colors.white,
  color: theme.colors.primary,
  paddingBottom: 'env(safe-area-inset-bottom)',

  [theme.breakpointsMediaQuery.up('lg')]: {
    display: 'none',
  },

  '@media print': {
    display: 'none',
  },
}));

export const BottomNavigationStyled = styled(BottomNavigation)(({ theme }) => ({
  '&&': {
    margin: '0 auto',
    height: theme.fonts.utility.size(32),
  },
}));

export const BottomNavigationActionStyled = styled(BottomNavigationAction, {
  shouldForwardProp: (prop) =>
    !['mobileFontSize', 'fontSize', 'mobileFontWeight', 'fontWeight'].includes(
      prop as string
    ),
})(({ theme, mobileFontSize, fontSize, mobileFontWeight, fontWeight }) => ({
  '&&': {
    'svg path': {
      stroke: theme.colors.primaryText,
    },
    'svg circle': {
      stroke: theme.colors.primaryText,
    },
    'svg rect': {
      stroke: theme.colors.primaryText,
    },
    'svg line': {
      stroke: theme.colors.primaryText,
    },
    color: theme.colors.primaryText,
    opacity: '50%',
    flex: 0.3,
    padding: 0,
    paddingTop: theme.spacings.unit(6 / 4),
    borderTop: `2px solid ${theme.colors.white}`,
    justifyContent: 'start',
    display: 'flex',
    minWidth: 'inherit',
  },

  '&&.Mui-selected': {
    color: theme.colors.primary,
    'svg path': {
      stroke: theme.colors.primary,
    },
    'svg rect': {
      stroke: theme.colors.primary,
    },
    'svg circle': {
      stroke: theme.colors.primary,
    },
    'svg line': {
      stroke: theme.colors.primary,
    },
    opacity: '100%',
    borderTop: `2px solid ${theme.colors.primary}`,
  },

  '.MuiBottomNavigationAction-label': {
    [theme.breakpointsMediaQuery.up('sm')]: {
      fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(5.5),
      lineHeight: fontSize
        ? `${fontSize + 4}px`
        : theme.fonts.utility.size(6.5),
      fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
    },
    [theme.breakpointsMediaQuery.down('sm')]: {
      fontSize: mobileFontSize
        ? `${mobileFontSize}px`
        : theme.fonts.utility.size(5.5),
      lineHeight: mobileFontSize
        ? `${mobileFontSize}px`
        : theme.fonts.utility.size(6.5),
      fontWeight: mobileFontWeight ? mobileFontWeight : 'inherit',
    },
    whiteSpace: 'pre-line',
  },

  svg: {
    height: theme.fonts.utility.size(12),
    width: theme.fonts.utility.size(12),
  },

  '.MuiBottomNavigationAction-label.Mui-selected': {
    [theme.breakpointsMediaQuery.up('sm')]: {
      fontSize: fontSize ? `${fontSize}px` : theme.fonts.utility.size(5.5),
      lineHeight: fontSize
        ? `${fontSize + 4}px`
        : theme.fonts.utility.size(6.5),
      fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
    },
    [theme.breakpointsMediaQuery.down('sm')]: {
      fontSize: mobileFontSize
        ? `${mobileFontSize}px`
        : theme.fonts.utility.size(5.5),
      lineHeight: mobileFontSize
        ? `${mobileFontSize}px`
        : theme.fonts.utility.size(6.5),
      fontWeight: mobileFontWeight ? mobileFontWeight : 'inherit',
    },
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(24 / 4),
  width: theme.spacings.unit(24 / 4),
  position: 'relative',
}));
