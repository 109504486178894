import Link from 'next/link';
import { useRouter } from 'next/router';
import { isBrowser } from 'corporate-utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Logger } from 'corporate-utils';

import { TextSize } from '../../models/enum';
import {
  IconWrapper,
  LinkVoice,
  LinkVoiceNoA,
  LinkVoiceTextStyled,
} from './LinkActionMegaMenu.style';

interface IProps {
  href?: string;
  text: string;
  title?: string;
  onClick?: () => void;
  onMouseOver?: (e?: any) => void;
  onMouseLeave?: (e: any, touch?: boolean) => void;
  size?: TextSize;
  type?: LinkType;
  icon?: React.ReactElement;
  noClick?: boolean;
  isActive?: 'active' | '';
  identify?: string | number;
  hover?: any | undefined;
  exitHover?: any | undefined;
  last?: boolean;
  mobileFontSize?: number;
  fontSize?: number;
  mobileFontWeight?: number;
  fontWeight?: number;
}

export enum LinkType {
  'primary',
  'secondary',
  'header',
  'headerSecondary',
}

const LinkAction: React.FC<IProps> = ({
  href,
  onClick,
  onMouseOver,
  onMouseLeave,
  size,
  type,
  text,
  title,
  icon,
  noClick,
  isActive,
  identify,
  hover,
  exitHover,
  last,
  mobileFontSize,
  fontSize,
  mobileFontWeight,
  fontWeight,
}: IProps) => {
  const router = useRouter();

  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const [touch, setTouch] = useState(false);

  const closeMenuOnResize = useCallback(() => {
    setSelected({});
  }, []);

  useEffect(() => {
    isBrowser() && window.addEventListener('resize', closeMenuOnResize);
    return () => {
      isBrowser() && window.removeEventListener('resize', closeMenuOnResize);
    };
  }, [closeMenuOnResize]);

  return href && !noClick ? (
    <Link href={href as string} passHref>
      <LinkVoice
        onClick={onClick}
        title={title || text}
        type={type}
        size={size}
        onTouchStart={(e: any) => {
          setTouch(true);
          if (onMouseOver && !selected[identify as number]) {
            onMouseOver(e);
            setSelected({
              [identify as number]: true,
            });
          }
          if (onMouseLeave && selected[identify as number]) {
            onMouseLeave(e, true);
            setSelected({
              [identify as number]: false,
            });
          }
          try {
            e.preventDefault();
            e.stopPropagation();
          } catch (err) {
            Logger.error(err);
          }
        }}
        onMouseOver={(e: any) => {
          exitHover?.current && clearTimeout(exitHover?.current);
          if (onMouseOver && !touch) {
            if (hover?.current) {
              clearTimeout(hover?.current);
            }
            hover.current = setTimeout(() => {
              onMouseOver(e);
              setSelected({
                [identify as number]: true,
              });
            }, 180);
          }
        }}
        onMouseLeave={(e: any) => {
          hover?.current && clearTimeout(hover?.current);
          if (onMouseLeave && !touch) {
            if (exitHover?.current) {
              clearTimeout(exitHover?.current);
            }
            exitHover.current = setTimeout(() => {
              onMouseLeave(e);
              setSelected({
                [identify as number]: false,
              });
            }, 180);
          } else if (onMouseLeave && touch) {
            setSelected({
              [identify as number]: false,
            });
          }
        }}
        last={last}
      >
        <LinkVoiceTextStyled
          type={type}
          isActive={isActive || (router.asPath === href ? 'active' : '')}
          mobileFontSize={mobileFontSize}
          fontSize={fontSize}
          mobileFontWeight={mobileFontWeight}
          fontWeight={fontWeight}
        >
          {text}
        </LinkVoiceTextStyled>
        {icon ? <IconWrapper>{icon}</IconWrapper> : null}
      </LinkVoice>
    </Link>
  ) : (
    <LinkVoiceNoA
      onTouchEnd={onClick}
      title={title || text}
      type={type}
      size={size}
      onTouchStart={(e: any) => {
        setTouch(true);
        if (onMouseOver && !selected[identify as number]) {
          onMouseOver(e);
          setSelected({
            [identify as number]: true,
          });
        }
        if (onMouseLeave && selected[identify as number]) {
          onMouseLeave(e, true);
          setSelected({
            [identify as number]: false,
          });
        }
        try {
          e.preventDefault();
          e.stopPropagation();
        } catch (err) {
          Logger.error(err);
        }
      }}
      onMouseOver={(e: any) => {
        exitHover?.current && clearTimeout(exitHover?.current);
        if (onMouseOver && !touch) {
          if (hover?.current) {
            clearTimeout(hover?.current);
          }
          hover.current = setTimeout(() => {
            onMouseOver(e);
            setSelected({
              [identify as number]: true,
            });
          }, 180);
        }
      }}
      onMouseLeave={(e: any) => {
        hover?.current && clearTimeout(hover?.current);
        if (onMouseLeave && !touch) {
          if (exitHover?.current) {
            clearTimeout(exitHover?.current);
          }
          exitHover.current = setTimeout(() => {
            onMouseLeave(e);
            setSelected({
              [identify as number]: false,
            });
          }, 180);
        } else if (onMouseLeave && touch) {
          setSelected({
            [identify as number]: false,
          });
        }
      }}
      last={last}
    >
      <LinkVoiceTextStyled
        type={type}
        isActive={isActive || (router.asPath === href ? 'active' : '')}
        mobileFontSize={mobileFontSize}
        fontSize={fontSize}
        mobileFontWeight={mobileFontWeight}
        fontWeight={fontWeight}
      >
        {text}
      </LinkVoiceTextStyled>
      {icon ? <IconWrapper>{icon}</IconWrapper> : null}
    </LinkVoiceNoA>
  );
};

export default LinkAction;
