import { styled } from '@mui/material';
import { Form } from 'formik';

export const ModalContentWrapper = styled('div')(({ theme }) => ({
  '& > div': {
    zIndex: 1,
  },
}));

export const HeaderContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  flexDirection: 'column',
  '& h3': {
    fontSize: theme.fonts.utility.size(10),
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    padding: `${theme.spacings.unit(4)} ${theme.spacings.unit(
      6.5
    )} ${theme.spacings.unit(4.5)}`,
    '& h3': {
      fontSize: theme.fonts.utility.size(20),
    },
  },
}));

export const BodyWrapper = styled('div')(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacings.unit(5),
  [theme.breakpointsMediaQuery.down('lg')]: {
    paddingLeft: theme.spacings.unit(0),
  },
  '& p:first-of-type': {
    marginTop: 0,
    paddingTop: theme.spacings.unit(10),
  },
}));

export const ImageWrapper = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(32 / 4),
  position: 'relative',
}));

export const TransaltionTestStyled = styled('p')(() => ({
  display: 'inline-block',
}));

export const StyledForm = styled(({ ...props }) => <Form {...props} />)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    paddingRight: theme.spacings.unit(4),
    '& input': {
      lineHeight: theme.spacings.unit(24 / 4),
    },
  })
);
