import React from 'react';
import { TextSize } from '../../models/enum';
import {
  StyledContactButton,
  ScrollFixerForButton,
  StyledContainer,
} from './ContactButton.style';

interface IProps {
  text?: string | Element | any;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  styleBtn: ButtonStyle;
  uppercase?: boolean;
  fullWidth?: boolean;
  onClick?: (event: any) => void;
  title?: string;
  className?: string;
  type?: string;
  textSize?: TextSize;
}

export enum ButtonStyle {
  'primary',
  'secondary',
  'tertiary',
  'outLined',
  'disabled',
}

const ContactButton: React.FC<IProps> = ({
  text,
  startIcon,
  endIcon,
  styleBtn,
  uppercase,
  fullWidth,
  onClick,
  title,
  className,
  type,
  textSize,
}: IProps) => {
  return (
    <StyledContainer>
      <StyledContactButton
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        styleBtn={styleBtn}
        uppercase={uppercase}
        fullWidth={fullWidth}
        title={title}
        className={className}
        text={text}
        type={type}
        textSize={textSize}
      />
      <ScrollFixerForButton></ScrollFixerForButton>
    </StyledContainer>
  );
};

export default ContactButton;
