import { styled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ExternalLinkButton, StyledLink } from '../../../styles/shared';

export const StyledFooter = styled('footer')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.unit(4),
  padding: `
    ${theme.spacings.unit(3)} 
    calc(${theme.spacings.unit(9)} + env(safe-area-inset-left)) 
    ${theme.spacings.unit(24)}  
    calc(${theme.spacings.unit(9)} + env(safe-area-inset-right))`,
  justifyContent: 'space-between',
  [theme.breakpointsMediaQuery.down('lg')]: {
    paddingBottom: `calc(${theme.spacings.unit(24)} + ${theme.spacings.unit(
      16
    )})`,
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    paddingLeft: theme.spacings.unit(26),
    paddingRight: theme.spacings.unit(26),
  },
  [theme.breakpointsMediaQuery.up('lg')]: {
    flexDirection: 'column',
    marginBottom: 0,
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    maxWidth: theme.spacings.unit(1960 / 4),
    paddingLeft: theme.spacings.unit(20 / 4),
    paddingRight: theme.spacings.unit(20 / 4),
  },
  margin: '0 auto',
  marginTop: theme.spacings.unit(4),
  '& > div': {
    flex: '1 1',
  },
  '@media print': {
    display: 'none',
  },
}));

export const FooterDivTop = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  [theme.breakpointsMediaQuery.up('md')]: {
    maxHeight: theme.spacings.unit(300),
  },
  [theme.breakpointsMediaQuery.down('md')]: {
    flexDirection: 'column',
    gap: theme.spacings.unit(4),
    width: '80%',
  },
}));

export const FooterDivNewsLetter = styled('div')(({ theme }) => ({
  width: '100%',
}));

export const FooterDivBottom = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpointsMediaQuery.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacings.unit(4),
  },
}));

export const FooterBottomLeft = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  flex: 0.45,
  gap: theme.spacings.unit(3),
}));

export const FooterBottomRight = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: theme.spacings.unit(6),
  flex: 0.38,
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 600,
  lineHeight: '160%',
  [theme.breakpointsMediaQuery.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const StyledGroupWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  gap: `${theme.spacings.unit(1.5)}`,
}));

export const CategoriesWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  flex: 0.5,
  gap: theme.spacings.unit(4),
  [theme.breakpointsMediaQuery.down('sm')]: {
    flex: 1,
  },
}));

export const ContactInfoMain = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: `${theme.spacings.unit(3)}`,
}));

export const ContactInfoSocials = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacings.unit(3),
}));

export const MainLinksWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 0.25,
}));

export const ContactInfoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 0.25,
  gap: `${theme.spacings.unit(6)}`,
}));

export const ContactInfoBody = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.colors.secondaryText,
}));

export const ContactInfoChild = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: theme.spacings.unit(-0.04),
  textDecoration: 'none',
  color: theme.colors.tertiaryText,
}));

export const LogoWrapper = styled('div')(({ theme }) => ({
  width: theme.spacings.unit(250 / 4),
  height: theme.spacings.unit(78 / 4),
  position: 'relative',
}));

export const FooterTitle = styled('div')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 500,
  lineHeight: '160%',
  letterSpacing: theme.spacings.unit(-0.02),
  color: theme.colors.secondaryText,
}));

export const StyledFooterLink = styled(StyledLink)(({ theme }) => ({
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: theme.spacings.unit(-0.04),
  textDecoration: 'none',
  color: theme.colors.tertiaryText,
}));

export const IconButtonStyled = styled(ExternalLinkButton)(({ theme }) => ({
  padding: 0,
}));

export const SocialLinkWrapper = styled('span')(({ theme }) => ({
  marginRight: theme.spacings.unit(4),
  '&:last-of-type': {
    marginRight: 0,
  },
}));

export const SocialName = styled('span')(({ theme }) => ({
  ...visuallyHidden,
}));

export const ContactInfoSocialsLinks = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacings.unit(3),
}));

export const CopyrightInfo = styled('div')(({ theme }) => ({
  color: theme.colors.secondaryText,
  fontSize: theme.fonts.utility.size(7),
  fontWeight: 400,
  lineHeight: '160%',
  letterSpacing: theme.spacings.unit(-0.04),
}));

export const AppInfo = styled('span')(({ theme }) => ({
  fontSize: theme.fonts.utility.size(8 / 2),
}));
