import { styled } from '@mui/material';
import { TextSize } from '../../models/enum';
import { LinkType } from './LinkActionMegaMenu';

export const LinkVoice = styled('a', {
  shouldForwardProp: (prop) => !['type', 'last'].includes(prop as string),
})(({ theme, type, size }) => {
  let typeStyle = {};
  let sizeStyle = {};

  switch (size) {
    case TextSize.small:
      sizeStyle = {
        fontSize: '.8rem',
      };
      break;
    case TextSize.medium:
    default:
      sizeStyle = {
        fontSize: '1rem',
      };
      break;
    case TextSize.large:
      sizeStyle = {
        fontSize: '1.2rem',
      };
      break;
  }

  switch (type) {
    case LinkType.header:
    default:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacings.unit(30 / 4),
        paddingRight: theme.spacings.unit(5),
        cursor: 'pointer',
        backgroundColor: 'transparent',
        width: 'auto',
        paddingLeft: 'initial',
        color: theme.colors.secondaryText,
        textTransform: 'none',
        height: '100%',
      };
      break;
    case LinkType.headerSecondary:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginLeft: theme.spacings.unit(5),
        cursor: 'pointer',
        padding: `${theme.spacings.unit(0.5)} ${theme.spacings.unit(1)}`,
        color: theme.colors.primary,
        textTransform: 'capitalize',
        '&:hover': {
          color: theme.colors.primary,
        },
      };
      break;
  }

  return {
    touchAction: 'pan-y',
    ...typeStyle,
    ...sizeStyle,
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  };
});

export const LinkVoiceTextStyled = styled('div', {
  shouldForwardProp: (prop) =>
    ![
      'isActive',
      'type',
      'mobileFontSize',
      'fontSize',
      'mobileFontWeight',
      'fontWeight ',
    ].includes(prop as string),
})(
  ({
    theme,
    isActive,
    type,
    mobileFontSize,
    fontSize,
    mobileFontWeight,
    fontWeight,
  }) => {
    let typeStyle: {
      [key: string]: any;
    } = {
      [theme.breakpointsMediaQuery.up('sm')]: {
        fontSize: fontSize ? `${fontSize}px` : 'inherit',
        lineHeight: fontSize ? `${fontSize + 4}px` : 'inherit',
        fontWeight: fontWeight ? (fontWeight as number) : 'inherit',
      },
      [theme.breakpointsMediaQuery.down('sm')]: {
        fontSize: mobileFontSize ? `${mobileFontSize}px` : 'inherit',
        lineHeight: mobileFontWeight ? `${mobileFontWeight}px` : 'inherit',
        fontWeight: mobileFontWeight ? mobileFontWeight : 'inherit',
      },
    };

    switch (type) {
      case LinkType.header:
      default:
        if (type === LinkType.header) {
          typeStyle = {
            ...typeStyle,
            whiteSpace: 'break-spaces',
          };
        }
        if (isActive === 'active' && type === LinkType.header) {
          typeStyle = {
            ...typeStyle,
            color: theme.colors.primary,
            borderBottom: `${theme.spacings.unit(0.5)} solid ${
              theme.colors.primary
            }`,
          };
        }
        return {
          marginTop: theme.spacings.unit(0.5),
          borderBottom: `${theme.spacings.unit(0.5)} solid ${
            theme.colors.background
          }`,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          ...typeStyle,
          '&:hover': {
            color: theme.colors.primary,
            borderBottom: `${theme.spacings.unit(0.5)} solid ${
              theme.colors.primary
            }`,
          },
        };
    }
  }
);

export const IconWrapper = styled('span', {
  shouldForwardProp: (prop) => prop !== 'text',
})(({ theme }) => {
  return {
    marginLeft: theme.spacings.unit(2),
    display: 'flex',
    '& svg': {
      width: '.8em',
      height: '.8em',
    },
  };
});

export const LinkVoiceNoA = styled('div', {
  shouldForwardProp: (prop) => !['type', 'last'].includes(prop as string),
})(({ theme, type, size, last }) => {
  let typeStyle = {};
  let sizeStyle = {};

  switch (size) {
    case TextSize.small:
      sizeStyle = {
        fontSize: '.8rem',
      };
      break;
    case TextSize.medium:
    default:
      sizeStyle = {
        fontSize: '1rem',
      };
      break;
    case TextSize.large:
      sizeStyle = {
        fontSize: '1.2rem',
      };
      break;
  }

  switch (type) {
    case LinkType.header:
    default:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        fontSize: theme.spacings.unit(30 / 4),
        paddingRight: !last ? theme.spacings.unit(5) : '0',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        width: 'auto',
        paddingLeft: 'initial',
        color: theme.colors.secondaryText,
        textTransform: 'none',
        height: '100%',
      };
      break;
    case LinkType.headerSecondary:
      typeStyle = {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        marginLeft: theme.spacings.unit(5),
        cursor: 'pointer',
        padding: `${theme.spacings.unit(0.5)} ${theme.spacings.unit(1)}`,
        color: theme.colors.primary,
        textTransform: 'capitalize',
        '&:hover': {
          color: theme.colors.primary,
        },
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      };
      break;
  }

  return {
    touchAction: 'pan-y',
    ...typeStyle,
    ...sizeStyle,
    textDecoration: 'none',
    // [theme.breakpointsMediaQuery.up('lg')]: {
    //   maxWidth: '15%',
    // },
  };
});
